import React from 'react'
import { ProjectsView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Projects'} />
}

const Projects = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <ProjectsView data={data} />
}

export const projectQuery = graphql`
  query projectQuery {
    contentfulCaseStudyGrid(page: { eq: "Projects" }) {
      ...CaseStudyGrid
    }
  }
`

export default Projects
